<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :block-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
    >
      <template #cell(certificatesList)="data">
        <ul
          class="ml-5"
          style="min-width: 28rem;"
        >
          <li
            v-for="item in data.item.certificates"
            :key="item.id"
            class="text-wrap"
            style="max-width: 28rem; text-align: left;"
          >
            <span>{{ item.name.en }}</span>
            <span v-if="item.name.en && item.name.ar"> - </span>
            <span>  {{ item.name.ar }}</span>
          </li>
        </ul>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'product-categories-with-certificates',
      viewContent: false,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'certificatesList', label: 'Certificates' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'product-categories-with-certificates?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },
  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>
